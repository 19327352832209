import jQuery from 'jquery';
import 'slick-carousel';

jQuery(function ($) {
  if ($('.gallery').length) {
    $('.gallery').slick({
      dots: false,
      infinite: false,
      speed: 300,
      centerMode: false,
      variableWidth: true,
      adaptiveHeight: false,
    });
    // var lightbox = new PhotoSwipeLightbox({
    //     gallery: '.gallery',
    //     children: 'a',
    //     pswpModule: PhotoSwipe
    // });
    // lightbox.init();
  }
});
